.product-collection {
    cursor: pointer;
    display: flex;
    justify-content: center; 
    align-items: center; 
    gap: 25px;
    padding: 20px;
}
.riccofiproduct{
    font-size: 25px;
    font-weight: 800;
    text-align: center;
    /* color: black;  */
    /* background-color: #ffcc00; 
    background-image: linear-gradient(to right, #ffcc00, #ff6666, #3399ff);  */
    border-radius: 5px;
    padding: 10px;

}
.product-collection img {
    width: 100%;
    height: 270px;
    border-radius: 8px; 
}


.col-md-12 {
    text-align: center;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .testimonial-section {
        padding: 16px 7px;
    }
    .product-collection {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center; 
        align-items: center; 
        gap: 20px;
        padding: 0px;
    }
    .product-collection img {
        width: 100%;
        height: 270px;
        border-radius: 8px; 
    }
}
