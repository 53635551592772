.carousel-indicators [data-bs-target]{
  background-color: black;
}
.marquediv{
  background-color: rgba(193, 193, 72, 0.733); color: red; padding: 5px ;
}
.navbar-home {
  margin-left: 10%;
  gap: 5px;
}

.iconcolor {
  color: #003f5c;
}

.iconcolor:hover {
  color: white;
}

.iconcolorr {
  color: #003f5c;
}

.iconcolorr:hover {
  color: white;
  font-size: 16px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: black;
  border-radius: 5px;
}

.mainnavbar {
  /* background-color: #91DFF5; */
  background-color: yellow;
  height: 102px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  color: dark;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
}

.logoimgage {
  height: 50px;
}

.listitem {
  color: black;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .carousel-indicators {
    top: 127px;
  }
  .navbar-home {
    margin-left: 0px;
  }

  .mainnavbar {
    background-color: yellow;
    height: auto;
  }

  .logoimgage {
    height: 30px;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 0.9em;
    height: 0.9em;
  }

  .w-100 {
    height: 130px;
  }

  .gap-3 {
    gap: 0rem !important;
  }

  .mb-2 {
    margin-bottom: -0.6rem !important;
  }

  .nav-link {
    color: dark;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 10px;
  }
}