/* Bestseller.css */

.bestseller{

}
.bestproduct {
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border: 2px solid red;
}

.bestsellertext {
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    /* background-color: #ffcc00; 
    background-image: linear-gradient(to right, #ffcc00, #ff6666, #3399ff);  */
    
}


.maindivbestproduct {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.bestseller {
    padding: 10px 20px;
}

.bestname {
    margin-top: -10px;
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .maindivbestproduct {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0px;
        gap: 25px;
    }

    .bestproduct {
        height: 100px;
    }

    .bestname {
        margin-top: -10px;
        font-weight: 500;
        font-size: 13px;

    }
    .bestsellertext {
        text-align: center;
        font-size: 20px;
        font-weight: 550;
    }
    .bestseller {
        padding: 13px 1px;
    }
}