.testimonial-section{
    padding: 60px 40px;
}
.section-title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    /* color: #ffcc00; 
    background-color: #ffcc00; 
    background-image: linear-gradient(to right, #ffcc00, #ff6666, #3399ff);  */
    border-radius: 5px;
 }

.testimonial-container {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;
    padding: 20px
}

.testimonial {
    background-color: #f1f1f1;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 10px;
    /* max-width: 350px; */
    width: 90%;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Align text left */
}

.testimonial-content {
    margin-bottom: 10px;
}

.testimonial-text {
    font-size: 1.0rem;
    margin-bottom: 10px;
}

.testimonial-author {
    font-weight: bold;
}

.testimonial-icon {
    font-size: 1rem;
    color: #777;
}

.textimonial-image {
    width: 20%;
    height: 100px;
    width: 100%;
    border-radius: 10px;
    align-items: center;
}

.imgaediv {
    width: 30%;

}

/* Media query for mobile screens */
@media (max-width: 768px) {
    .testimonial-container{
        flex-direction: row;
        justify-content: center;
        padding: 0px; /* Adjusted padding syntax */
        margin-top: 0px;
    }

    .section-title {
        margin-top: 21px !important;
        font-size: 2rem;
        margin-bottom: 0px;
    }
    .testimonial {
        padding: -3px;
    }
    .section-title {
        font-size: 30px;}

}
