/* Footer.css */

/* .footer {
    background-color:#94938e;
    color: #fff;
    padding: 50px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .col-lg-6 {
    width: 50%;
  }
  
  h3 {
    margin-bottom: 20px;
  } */

.footerlogo {
  height: 50px;
  margin-top: 50px;
  margin-left: 100px;
}
.fistdivfooter div i{
  font-size: 30px;
}
.newfooter {
  margin-top: -55px;
  padding: 100px;
  display: flex;
  width: 100%;
  gap: 5px;
  color: #595959;
  font-size: 17px;
  font-family: Futura sans-serif;
  background-color:#f1f1f1;
}

.fistdivfooter {
  width: 100%;
}

.seconddivfooter {
  margin-left: 100px;
  width: 100%;
  line-height: 10px;
}

.thirddivfooter {
  width: 100%
}

.footerheanding {
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
}

.footerlinks {
  text-decoration: none;
  color: #595959;
}

@media screen and (max-width: 768px) {
  .newfooter {
    margin-top: 0px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    font-size: 15px;
  }

  .seconddivfooter {
    width: 200px;
    line-height: 10px;
    margin-left: 0px;
  }

  .thirddivfooter {
    width: 200px;
  }
  .footerlogo {
    height: 50px;
    margin-top: 0px;
    margin-left: 10px;
  }
}