.carousel-control-prev,
.carousel-control-next {
   color: orange;
}
.singlrpageimage{
   height: 400px;
   cursor: pointer;
}
.singlrpageimageslide{
   height: 100px;
   cursor: pointer;
}
.productdetailsheight{
   margin-top: 0px;
}
.descriptionheading{
   font-size: 25px;
   opacity: 0.9;
   font-weight: 800;
   /* background-color: rgba(240, 255, 255, 0); */
}
.descriptionpara{
   font-size: 16px;
   font-weight: 500;
   opacity: 0.9;
   letter-spacing: 1px;
   text-align: justify;
}
.descriptiondiv{
   margin-top: 40px;
   line-height: 20px;
}
@media (max-width:768px) {
   .singlrpageimage{
      height: 200px !important;
      cursor: pointer;
   }
   .productdetailsheight{
      margin-top: 0px;
   }
   .singlrpageimageslide {
      height: 90px;
      cursor: pointer;
  }
}